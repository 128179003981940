import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storage: Storage | null = null;

  constructor(private storageFactory: Storage) {
    this.initializeStorage();
  }

  private async initializeStorage(): Promise<void> {
    this.storage = await this.storageFactory.create();
  }

  async setData(key: string, value: any): Promise<void> {
    await this.initializeStorage();
    await Promise.resolve(this.storage?.set(key, value));
  }

  async getData(key: string): Promise<any> {
    await this.initializeStorage();
    return Promise.resolve(this.storage?.get(key));
  }

  async removeData(key: string): Promise<void> {
    await this.initializeStorage();
    await Promise.resolve(this.storage?.remove(key));
  }
}
