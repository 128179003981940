import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReservationService {
  public court: CourtReservation[] = [];
  public cabana: CabanaReservation[] = [];

  constructor() {
      //Constructor Logic
  }

  /**
   * Sync reservation details from front-end with
   * data service object
   * @param reservationDetails
   */
  public async syncCourtReservation(reservationDetails) {
    this.court = reservationDetails;
  }

  public async syncCabanaReservation(reservationDetails) {
    this.cabana = reservationDetails;
  }
}

//Court Struct
export interface CourtReservation {
  courtType: String
  dateSelected: String
  startTime: String
  endTime: String
  guestCount: String
  courtCount: String
  cost: String
  time: String
}

//Cabana Struct
export interface CabanaReservation {
  cabanaType: String
  dateSelected: String
  startTime: String
  endTime: String
  guestCount: String
  cabanaCount: String
}
