import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  public event: Event[] = [];

  constructor() {
    //Constructor logic
  }

  public async syncEventDetails(eventDetails) {
    this.event = eventDetails;
  }
}

//Event struct
export interface Event {
  capacity: String;
  description: Array<any>;
  start: Array<any>;
  end: Array<any>;
  logo: Array<any>;
  name: Array<any>;
  summary: String;
  venue: Array<any>;
}
