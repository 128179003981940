import { Component, Input, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {Cart, RestaurantService} from "../../services/restaurant.service";
import {AlertController} from "@ionic/angular";

@Component({
  selector: 'app-restaurant-component',
  templateUrl: './restaurant-component.component.html',
  styleUrls: ['./restaurant-component.component.scss'],
})
export class RestaurantComponentComponent implements OnInit {
  @Input() restaurantData: [];
  @Input() image;

  order;
  constructor(private router: Router, private restaurantService: RestaurantService, private alertController: AlertController) {
    this.restaurantService.currentOrder.subscribe(order => this.order = order);
  }

  ngOnInit() {
  }


  async presentAlertConfirm(cart,name) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Cart Alert',
      subHeader: '',
      message: 'Your cart will be cleared if you continue.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {

          }
        }, {
          text: 'Okay',
          handler: () => {
            this.restaurantService.setOrder(cart);
            this.router.navigateByUrl('/restaurants/' + name);
          }
        }
      ]
    });

    await alert.present();
  }

    navigateToRestaurant(name) {
      let cart: Cart = <Cart>{};

      cart.restaurant = name;
      cart.total = 0;
      cart.items = [];
      cart.pendingItem = '';

      if(this.order.restaurant === name && this.order.items.length > 0) {
        //This will keep you in the same cart if there are items and you click the same restaurant
        this.router.navigateByUrl('/restaurants/' + name);
        return
      } else {
        if(this.order.items.length > 0) {
          //Checks to see if you have items in your cart and warns you it will clear it as you are clicking a different restaurant
          this.presentAlertConfirm(cart,name)
        } else {
          this.restaurantService.setOrder(cart);
          this.router.navigateByUrl('/restaurants/' + name);
        }
      }
    }
}

